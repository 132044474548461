























































import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'MigrateUnavailable'
})
export default class MigrateUnavailable extends Vue {
}
